/**
 * Created by zhangxingbo on 16/11/28.
 */

export const ID = '@budget-manage'

export default {
  ID: ID,
  // OPT
  GET_DIMENSION_TYPE: `${ID}/GET_DIMENSION_TYPE`,
  GET_DIMENSION_INFO: `${ID}/GET_DIMENSION_INFO`,
  GET_DIMENSION_NODE: `${ID}/GET_DIMENSION_NODE`,
  GET_DIMENSION_LIST: `${ID}/GET_DIMENSION_LIST`,
  SEARCH_DIMENSION: `${ID}/SEARCH_DIMENSION`,
  GET_BUDGET_INFO_ROOT_NODE: `${ID}/GET_BUDGET_INFO_ROOT_NODE`,
  GET_BUDGET_NODE: `${ID}/GET_BUDGET_NODE`,
  GET_BUDGET_NODE_DATA: `${ID}/GET_BUDGET_NODE_DATA`,
  SEARCH_BUDGET_NODE: `${ID}/SEARCH_BUDGET_NODE`,
  GET_BUDGET_NODE_AND_PATH_BY_ID: `${ID}/GET_BUDGET_NODE_AND_PATH_BY_ID`,
  GET_BUDGET_NODE_BY_ID: `${ID}/GET_BUDGET_NODE_BY_ID`,
  UPDATE_BUDGET: `${ID}/UPDATE_BUDGET`,
  SAVE_BUDGET: `${ID}/SAVE_BUDGET`,
  // OPT
  GET_ACTIVE_DIMENSION: `${ID}/GET_ACTIVE_DIMENSION`,
  SAVE_BUDGET_INFO: `${ID}/SAVE_BUDGET_INFO`,
  UPDATE_BUDGET_INFO: `${ID}/UPDATE_BUDGET_INFO`,
  GET_ALL_BUDGET: `${ID}/GET_ALL_BUDGET`,
  GET_BUDGET_INFO_ID: `${ID}/GET_BUDGET_INFO_ID`,
  DELETE_BUDGET_BY_ID: `${ID}/DELETE_BUDGET_BY_ID`,
  GET_UNAPPLY_BUDGET: `${ID}/GET_UNAPPLY_BUDGET`,
  UPATE_UNAPPLY_BUDGET: `${ID}/UPATE_UNAPPLY_BUDGET`,
  GET_BUDGET_LOGS: `${ID}/GET_BUDGET_LOGS`,
  SEARCH_BUDGET: `${ID}/SEARCH_BUDGET`,
  UPDATE_BUDGET_NAME: `${ID}/UPDATE_BUDGET_NAME`,
  GET_ALL_DIMENSION: `${ID}/GET_ALL_DIMENSION`,
  GET_DIMENSION_ENTITY: `${ID}/GET_DIMENSION_ENTITY`,
  POST_CHECK_PRIVATE_ID: `${ID}/POST_CHECK_PRIVATE_ID`,
  EXPORT_BUDGET: `EXPORT_BUDGET`,
  GET_BUDGET_RESULT: `${ID}/GET_BUDGET_RESULT`
}
