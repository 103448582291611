import { Resource } from '@ekuaibao/fetch'
import { QuerySelect } from 'ekbc-query-builder'
import key from './key'
const budgetManage = new Resource('/api/v1/budget')
const budgetManageOpt = new Resource('/api/v2/budget')
const entityAction = new Resource('/api/flow/v2/referables')
const checkAction = new Resource('/api/v1/private/check')
const budgetEdit = new Resource('/api/v2/budgetEdit')
const budgetQuick = new Resource('/api/v2/budget/info')
//请求地址：/v2/budget/info/copy/${id}
// 请求方式：POST
// id:被复制的预算包ID

export function getDimensionType() {
  return {
    type: key.GET_DIMENSION_TYPE,
    payload: budgetManageOpt.GET('/optimised/dimension')
  }
}
export function getDimensionInfo(params) {
  let query = new QuerySelect()
    .filterBy(`id=="${params.id}"`)
    .filterBy(
      params.type === 'PROJECT' && params.dimensionId !== 'settlement' ? `dimensionId=="${params.dimensionId}"` : ''
    )
    .orderBy('code', 'ASC')
    .orderBy('name', 'ASC')
    .limit(params.start, params.count)
    .select(`...`)
  query = query.value()
  let query2 = {
    dimensionId: params.dimensionId,
    dimensionType: params.type
  }
  return {
    type: key.GET_DIMENSION_INFO,
    payload: budgetManageOpt.POST('/optimised/dimension/query', { query, ...query2 })
  }
}
export function getDimensionNode(params) {
  return {
    type: key.GET_DIMENSION_NODE,
    payload: budgetManageOpt.POST('/optimised/dimension-node', { ...params })
  }
}
export function getDimensionList(params) {
  let query = new QuerySelect()
    .filterBy(params.type === 'STAFF' || params.dimensionId === 'settlement' ? '' : `parentId=="${params.parentId}"`)
    .filterBy(
      params.type === 'PROJECT' && params.dimensionId !== 'settlement' ? `dimensionId=="${params.dimensionId}"` : ''
    )
    .filterBy(`active==true`)
    .orderBy('code', 'ASC')
    .orderBy('name', 'ASC')
    .limit(params.start, params.count)
    .select(`...`)
  query = query.value()
  let query2 = {
    dimensionId: params.dimensionId,
    dimensionType: params.type
  }
  return {
    type: key.GET_DIMENSION_LIST,
    payload: budgetManageOpt.POST('/optimised/dimension/query', { query, ...query2 })
  }
}
export function searchDimension(params) {
  let query = {
    keyWord: params.searchText,
    start: params.start,
    count: params.count,
    dimensionType: params.type,
    dimensionId: params.dimensionId,
    parentId: params.parentId
  }
  return {
    type: key.SEARCH_DIMENSION,
    payload: budgetManageOpt.POST('/optimised/dimension/search', { ...query })
  }
}
export function getBudgetInfoRootNode(id) {
  return {
    type: key.GET_BUDGET_INFO_ROOT_NODE,
    payload: budgetManageOpt.GET('/info/budgetInfoRootNode/$id', { id })
  }
}
export function getBudgetNode(params) {
  let query = new QuerySelect()
    .orderBy('nodeId', 'ASC')
    .limit(params.start, params.count)
    .select(`...`)
  query = query.value()
  return {
    type: key.GET_BUDGET_NODE,
    payload: budgetManageOpt.POST(`/info/getBudgetNode/$budgetInfoId/$budgetNodeId/$budgetInfoVersion`, {
      ...query,
      budgetInfoId: params.budgetInfoId,
      budgetNodeId: params.budgetNodeId,
      budgetInfoVersion: params.budgetInfoVersion
    })
  }
}
export function getBudgetNodeData(params) {
  let query = new QuerySelect()
    .orderBy('nodeId', 'ASC')
    .limit(params.start, params.count)
    .select(`...`)
  query = query.value()
  return {
    type: key.GET_BUDGET_NODE_DATA,
    payload: budgetManageOpt.POST(`/info/getBudgetNodeData/$budgetInfoId/$budgetNodeId/$budgetInfoVersion`, {
      ...query,
      budgetInfoId: params.budgetInfoId,
      budgetNodeId: params.budgetNodeId,
      budgetInfoVersion: params.budgetInfoVersion
    })
  }
}
export function searchBudgetNode(params) {
  const { searchType, budgetInfoId, budgetInfoVersion, searchText } = params
  let query = new QuerySelect()
    .orderBy('nodeId', 'ASC')
    .limit(params.start, params.count)
    .select(`...`)
  query = query.value()
  const paramData = {
    query: {
      ...query
    },
    budgetInfoId,
    budgetInfoVersion
  }
  const cParams = searchType === 'name' ? { ...paramData, name: searchText } : { ...paramData, code: searchText }
  return {
    type: key.SEARCH_BUDGET_NODE,
    payload: budgetManageOpt.POST(`/info/searchBudgetNode/$budgetInfoId/$budgetInfoVersion`, { ...cParams })
  }
}
export function getBudgetNodeAndPathById(params) {
  return {
    type: key.GET_BUDGET_NODE_AND_PATH_BY_ID,
    payload: budgetManageOpt.GET(`/info/getBudgetNodeAndPathById/$budgetInfoId/$budgetNodeId/$budgetInfoVersion`, {
      ...params
    })
  }
}
export function getBudgetNodeById(params) {
  return {
    type: key.GET_BUDGET_NODE_BY_ID,
    payload: budgetManageOpt.GET(`/info/getBudgetNodeById/$budgetInfoId/$budgetNodeId/$budgetInfoVersion`, {
      ...params
    })
  }
}
export function updateBudget(params, done) {
  return {
    type: key.UPDATE_BUDGET,
    payload: budgetManageOpt.PUT(`/info/$budgetInfoId/async`, { ...params }),
    done
  }
}
// 获取预算包异步保存结果
export function getBudgetResult(params) {
  return {
    type: key.GET_BUDGET_RESULT,
    payload: budgetManageOpt.GET(`/info/$budgetInfoId/async`, { ...params })
  }
}

export function saveBudget(params, done) {
  return {
    type: key.SAVE_BUDGET,
    payload: budgetManageOpt.POST(`/info`, { ...params }),
    done
  }
}
export function getActiveDimension() {
  return {
    type: key.GET_ACTIVE_DIMENSION,
    payload: budgetManage.GET('/config/dimension')
  }
}

export function getAllDimension() {
  return {
    type: key.GET_ALL_DIMENSION,
    payload: budgetManage.GET('/config/dimension/all')
  }
}

export function saveBudgetInfo(param, done) {
  let data = { ...param }
  return {
    type: key.SAVE_BUDGET_INFO,
    payload: budgetManage.POST('/info', data),
    done
  }
}

export function updateBudgetInfo(param, done) {
  let data = { ...param }
  return {
    type: key.UPDATE_BUDGET_INFO,
    payload: budgetManage.PUT('/info', data),
    done
  }
}

export function getAllBudget() {
  return {
    type: key.GET_ALL_BUDGET,
    payload: budgetManage.GET('/info')
  }
}

export function getBudgetById(id, done) {
  return {
    type: key.GET_BUDGET_INFO_ID,
    payload: budgetManage.GET('/info/$id', { id }),
    done
  }
}

export function deleteBudgetById(id, done) {
  return {
    type: key.DELETE_BUDGET_BY_ID,
    payload: budgetManage.DELETE('/info/$id', { id }),
    done
  }
}

export function getBudgetUnapply(done) {
  return {
    type: key.GET_UNAPPLY_BUDGET,
    payload: budgetManage.GET('/config/unapply'),
    done
  }
}

export function updateUnapplyBudget(param) {
  return {
    type: key.GET_UNAPPLY_BUDGET,
    payload: budgetManage.PUT('/config/unapply', param)
  }
}

export function getBudgetLogsById(id, done) {
  return {
    type: key.GET_BUDGET_LOGS,
    payload: budgetManage.GET('/info/$id/log', { id }),
    done
  }
}

export function searchBudget(content, done) {
  return {
    type: key.SEARCH_BUDGET,
    payload: budgetManage.PUT('/info/search', { content: content }),
    done
  }
}

export function updateBudgetNamebyId(id, name) {
  return {
    type: key.UPDATE_BUDGET_NAME,
    payload: budgetManage.PUT('/info/${id}/name/${name}', { id, name: name }),
    done
  }
}

export function getDimensionEntityByNames(entityList) {
  let promiseList = entityList.map(entity => {
    return entityAction.GET('/$name', { name: entity.dataType.entity, withVisibility: true })
  })
  return {
    type: key.GET_DIMENSION_ENTITY,
    payload: Promise.all(promiseList).then(data => {
      return data.map((items, index) => {
        return { ...entityList[index], items: items.items }
      })
    })
  }
}

export function exportExcel(param, budgetId) {
  let { data } = param
  return {
    type: key.EXPORT_BUDGET,
    payload: budgetManage.POST('/info/$budgetId/excel', { budgetId, data })
  }
}

export function checkprivate(name) {
  return {
    type: key.POST_CHECK_PRIVATE_ID,
    payload: checkAction.POST('/$name', { name })
  }
}

// 新建预算包并发起编制
export function saveBudgetAndCreateBudgetEdit(params) {
  return budgetEdit.POST('/create', params)
}

// 已经创建的预算包发起编制
export function updateBudgetAndCreatebudgetEdit(params) {
  return budgetEdit.POST('/create/$budgetId', { ...params })
}

// 复制预算包
export function copyBudget(params) {
  return budgetManageOpt.POST('/info/copy/$id/async', params)
}

// 获取编制状态预算包树的根节点
export function getInfoAndRootNode(params) {
  return budgetEdit.GET('/infoAndRootNode/$budgetEditInfoId', { ...params })
}

// 快速拆解获取分解维度的层级
export function getBudetLevel(params) {
  return budgetQuick.POST('/quick/$budgetId/$budgetVersion/node/$nodeId/levelInfo', { ...params })
}

// 快速拆解保存接口
export function saveBudgetQuick(params) {
  return budgetQuick.POST('/quick/$budgetId/$budgetVersion/node/$nodeId/async', { ...params })
}

// 获取预算调整URL
export function getBudgetEditAdjustUrl(params) {
  return budgetEdit.GET('/adjust/url', params)
}
