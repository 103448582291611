/**
 * Created by zhangXingBo on 16/11/28.
 */
import { app as api } from '@ekuaibao/whispered'
const budgetAction = require('./budget-manage.action')

export default [
  {
    id: '@budget-manage',
    reducer: () => import('./budget-manage.reducer'),
    path: '/budget-manage',
    ref: '/',
    onload: () => import('./budget-manage-view'),
    onbefore: async (app) => {
      const models = await import('./models');
      app.collection.register(models.default);
    },

    'get:dimension:type'() {
      api.dispatch(budgetAction.getDimensionType())
    },

    'get:active:dimension:list'() {
      api.dispatch(budgetAction.getActiveDimension())
    },

    'get:all:dimension:list'() {
      api.dispatch(budgetAction.getAllDimension())
    },
    'get:budget:result'(params) {
      return api.dispatch(budgetAction.getBudgetResult(params))
    }
  },
  {
    id: '@budget-manage',
    path: '/budget-manage',
    ref: '/',
    exact: true,
    onload: () => import('./budget-manage-view')
  },
  {
    point: '@@layers',
    prefix: '@budget-manage',
    onload: () => import('./layers')
  }
]
